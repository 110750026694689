$react: #ffd200;
$react-light: #61dafb;
$primary: $react;
$primary-light: $react-light;
$link: $primary;

$shade-light: #fafafa;

.menu-list .active-link,
.menu-list .router-link-active {
  color: #fff;
  background-color: $link;
}

.not-found {
  i {
    font-size: 20px;
    margin-right: 8px;
  }

  .title {
    letter-spacing: 0px;
    font-weight: normal;
    font-size: 24px;
    text-transform: none;
  }
}

header {
  font-weight: bold;
  font-family: Arial, serif;

  span {
    letter-spacing: 0px;

    &.brand-first {
      color: #fff;
    }

    &.brand-second {
      color: #ccc;
    }

    &.brand-third {
      color: $primary-light;
    }
  }

  .navbar-item.nav-home {
    border: 3px solid transparent;
    border-radius: 0%;

    &:hover {
      border-right: 3px solid $primary-light;
      border-left: 3px solid $primary-light;
    }
  }

  .fab {
    font-size: 24px;

    &.js-logo {
      color: $primary-light;
    }
  }

  .buttons {
    i.fab {
      color: #fff;
      margin-left: 20px;
      margin-right: 10px;

      &:hover {
        color: $primary-light;
      }
    }
  }
}

.edit-detail {
  .input[readonly] {
    background-color: $shade-light;
  }

  .input::placeholder {
    color: #ccc;
    font-style: italic;
  }
}

.content-title-group {
  margin-bottom: 16px;

  h2 {
    border-left: 16px solid $primary;
    border-bottom: 2px solid $primary;
    padding-left: 8px;
    padding-right: 16px;
    display: inline-block;
    text-transform: uppercase;
    color: rgb(51, 49, 50);
    font-family: "Trade Gothic Condensed", sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 0;
  }

  button.button {
    display: inline-block;
    padding: 5px 20px 5px 20px;
    font-family: "Trade Gothic Condensed", sans-serif;
    font-weight: 600;

    &:enabled {
      color: rgb(51, 49, 50);
      background-color: #ffffff;
    }

    &:hover:enabled {
      color: $link;
    }
  }
}

ul.list {
  box-shadow: none;
}

div.card-content {
  background-color: $shade-light;

  .name {
    font-size: 28px;
    color: #000;
  }

  .description {
    font-size: 20px;
    color: #999;
  }

}

.card {
  margin-bottom: 2em;
}

label.label {
  font-weight: normal;
}

p.card-header-title {
  background-color: $primary;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  display: block;
  padding-left: 24px;
}

.card-footer button {
  font-size: 16px;

  i {
    margin-right: 10px;
  }

  color: #888;

  &:hover {
    color: $link;
  }
}

.modal-card-foot button {
  display: inline-block;
  width: 80px;
}

.modal-card-head,
.modal-card-body {
  text-align: center;
}

.field {
  margin-bottom: 0.75rem;
}

.navbar-burger {
  margin-left: auto;
}

button.link {
  background: none;
  border: none;
  cursor: pointer;
}

nav.brp-navbar {

  div.brp-navbar-brand {
    font-size: 24px;
    height: 56px;
    vertical-align: middle;
    white-space: nowrap;
    background-color: #2f2f2f;
    color: #ffffff;

    a.brp-nav-home {
      color: rgb(249, 249, 249);

      img {
        display: inline-block;
        width: 56px;
        height: 56px;
      }

      span {
        display: inline-block;
        line-height: 1;
        outline: none;
        overflow: hidden;
        padding: 16px 0;
        text-decoration: none;
        text-overflow: ellipsis;
      }
    }
  }
}

.content-container {
  font-family: "Trade Gothic Roman", sans-serif;
  color: rgb(51, 49, 50);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 24px;
  padding-right: 24px;

  p {
    &.instruction--note {
      margin-top: 1rem;
    }

    a {
      text-decoration: underline;
      color: rgb(51, 49, 50);

      &:hover {
        color: $link;
      }
    }
  }

  .field-wrapper {
    position: relative;
    margin-top: 10px;

    label {
      display: inline-block;
      width: 150px;
      font-weight: bold;
    }

    input {
      width: 200px;
      margin-left: 10px;
      padding: 5px;
      box-sizing: border-box;
    }

    .copy-icon {
      padding-left: 10px;
      cursor: pointer;
    }

    .tooltip {
      background-color: #f2f2f2;
      color: #333;
      margin-left: 10px;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      white-space: nowrap;
      display: inline;
    }

    .error-message {
      color: #cc0000;
    }
  }

  div.instructions {
    margin-top: 2rem;

    .download-section {
      padding-top: 15px;

      h3 {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
      }

      ul {
        margin-top: 0;
        margin-bottom: 0;

        li {
          margin-left: 15px;
          list-style-type: none;

          i.fa-download {
            font-size: 15px;
            margin-right: 10px;
            color: rgba(0, 0, 0, 0.5);
          }

          a {
            text-decoration: underline;
            color: rgb(51, 49, 50);

            &:hover {
              color: $link;
            }
          }
        }
      }
    }
  }
}